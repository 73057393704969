import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import css from './SearchMapPriceLabel.module.css';
const filterClasses = {
  signedUp: css.signedUp, // No shadow
  boothConfigured: css.boothConfigured, // Black shadow
  onboarded: css.onboarded, // Green shadow
  activeEngaged: css.activeEngaged, // Purple shadow
  tradeShow: css.tradeShow, // Blue shadow
};

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listing,
      onListingClicked,
      isActive,
      config,
    } = this.props;
    const currentListing = ensureListing(listing);
    const { attributes } = currentListing.author || {};
    const { tag, rfpBids = 0, ratings = 0 } = attributes.profile.publicData || {};
    const { price, publicData } = currentListing.attributes || {};
    const {
      boothType,
      stripeOnBoard,
    } = publicData || {};
    // Create formatted price if currency is known or alternatively show just the unknown currency.
    const formattedPrice =
      price && price.currency === config.currency
        ? formatMoney(intl, price)
        : price?.currency
        ? price.currency
        : null;

    const classes = classNames(rootClassName || css.root, className);

    let appliedClass = '';
    if (tag == 'Trade Show') {
      appliedClass = filterClasses.tradeShow; // Filter 5
    } else if ((rfpBids > 0) || (ratings > 0)) {
      appliedClass = filterClasses.activeEngaged; // Filter 4
    } else if (stripeOnBoard) {
      appliedClass = filterClasses.onboarded; // Filter 3
    } else if (boothType && boothType.length) {
      appliedClass = filterClasses.boothConfigured; // Filter 2
    } else {
      appliedClass = filterClasses.signedUp; // Filter 1
    }

    const priceLabelClasses = classNames(css.priceLabel, appliedClass, {
      [css.mapLabelActive]: isActive,
      [css.noPriceSetLabel]: !formattedPrice,
    });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>
          <span className={css.price}>
            {/* {formattedPrice} */}
            </span>
          </div>
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
